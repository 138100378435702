import { api } from './index';
import { endpoints } from 'variables/endpoint-urls';
import { extractResults } from './util';

// #region types

interface GetEventUpdatesResult {
  has_interested_events: boolean;
  interested_events: InterestedEvent[];
}

export interface InterestedEvent {
  event_date: string;
  event_name: string;
  event_slug: string;
  event_uuid: string;
  new_attendee_count: number;
}

// #endregion

export const feedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEventUpdates: builder.query<GetEventUpdatesResult, void>({
      query: () => endpoints.feed.get.event_updates,
      transformResponse: extractResults,
      providesTags: (result) =>
        result
          ? [
              ...result.interested_events.map(({ event_uuid }) => ({
                type: 'EventUpdates' as const,
                id: event_uuid,
              })),
            ]
          : ['EventUpdates'],
    }),
  }),
});
