import React, { useState } from 'react';
import {
  Tooltip,
  IconButton,
  Popper,
  Paper,
  ClickAwayListener,
  Box,
  Badge,
} from '@mui/material';
import { MuiIconManifest } from 'utils/iconManifest';
import { AlertCenter } from 'components/Home/AlertCenter';
import { feedApi } from 'redux/reducers/api/feed';
import { sendPendoEvent } from 'analytics';

export const AlertCenterPopperButton = () => {
  const { data: eventUpdates, isLoading: eventUpdatesLoading } =
    feedApi.useGetEventUpdatesQuery();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    sendPendoEvent('Nav Bar > Alert Center');
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const badgeCount =
    !eventUpdatesLoading &&
    eventUpdates &&
    eventUpdates.has_interested_events &&
    eventUpdates.interested_events?.length
      ? eventUpdates.interested_events.length
      : 0;

  return (
    <>
      <Tooltip arrow title="Event updates">
        <IconButton color="inherit" onClick={handleClick}>
          <Badge
            color="error"
            invisible={
              !eventUpdates?.interested_events.length ||
              !eventUpdates.has_interested_events ||
              eventUpdatesLoading
            }
            badgeContent={badgeCount}
          >
            <MuiIconManifest.NotificationsIcon />
          </Badge>
        </IconButton>
      </Tooltip>

      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        style={{ zIndex: 1300 }}
        disablePortal
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper
            elevation={3}
            sx={{
              maxWidth: 600,
            }}
          >
            <Box p={1}>
              <AlertCenter />
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
