/**
 * Add a new item to an array, overwriting any duplicates that match the compare function.
 */
export function appendOrReplace<T>(
  arr: T[],
  newItem: T,
  compareFunc: (a: T, b: T) => boolean
): T[] {
  // Remove any items that match the new item.
  const updatedArr = arr.filter((item) => !compareFunc(item, newItem));
  // Add the new item.
  return [...updatedArr, newItem];
}
