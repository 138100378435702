import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import cookies from 'js-cookie';
export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: '/',
    prepareHeaders: (headers, { type: _type }) => {
      const csrfToken = cookies.get('csrftoken');
      if (csrfToken) {
        headers.set('X-CSRFToken', csrfToken);
      }
    },
  }),
  tagTypes: [
    'EventAttendees',
    'EventSponsors',
    'EventSummary',
    'EventAttendeesWeekly',
    'Prospects',
    'EventPlanner',
    'PlannerEvent',
    'PlannerViews',
    'Search',
    'Directory',
    'ProspectLists',
    'Credits',
    'UserState',
    'Settings',
    'Settings.TeamPreferences',
    'EventReports',
    'EventReports.Events',
    'Options.ProspectList',
    'Subindustries',
    'Industries',
    'IndustryGroups',
    'Sectors',
    'EventUpdates',
  ],
  /**
   * Don't put anything here, use `api.injectEndpoints` in separate API slices.
   */
  endpoints: () => ({}),
});
