import React, { PropsWithChildren } from 'react';
import { useAppLogout } from 'utils/appLogout';

const LogoutButton = ({ children }: PropsWithChildren<{}>) => {
  const logout = useAppLogout();

  return (
    <div
      onClick={() => {
        logout();
      }}
    >
      {children || 'Logout'}
    </div>
  );
};

export default LogoutButton;
