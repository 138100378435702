// docs: https://developers.google.com/analytics/devguides/collection/upgrade/analyticsjs
import { ANALYTICS_ENABLED } from './variables/constants';
import { store, useAppDispatch } from './redux/store';

import { useUserState } from './hooks/common/useUserState';
import { useEffect, useMemo } from 'react';
import { setUserIdentifiers } from './redux/reducers/auth';
import { userApi } from './redux/reducers/api/user';
import { useHistory } from 'react-router';
import { usePostHog as usePostHogProvider } from 'posthog-js/react';

declare global {
  // noinspection JSUnusedGlobalSymbols
  interface Window {
    gtag(method: 'set', props: Record<string, any>): void;
    gtag(
      method: string | undefined,
      action: string | undefined,
      props?: Record<string, any>
    ): void;

    pendo: {
      getVisitorId?: () => string;
      track(category: string, props?: Record<string, any>): void;
      initialize(config: Record<string, any>): void;
      isReady?: () => boolean;
      identify(config: Record<string, any>): void;
      clearSession(): void;
      isAnonymousVisitor(): boolean;
      teardown(): void;
    };
  }
}

export function usePendo() {
  const { isAuthenticated, email, profile, team, subscription, impersonating } =
    useUserState();

  const pendoIdentity = useMemo(() => {
    return !isAuthenticated
      ? {
          visitor: { id: 'VISITOR-UNIQUE-ID' },
        }
      : {
          visitor: {
            id: profile.uuid,
            email,
            name: profile.name,
            subscription: subscription,
          },
          account: {
            id: team.uuid,
            team: team.name,
          },
        };
  }, [
    email,
    isAuthenticated,
    profile.name,
    profile.uuid,
    subscription,
    team.name,
    team.uuid,
  ]);

  /*
   * useEffect won't catch Pendo becoming ready because that doesn't trigger a React re-render. The effect that follows
   * this one should take care of that. Here we just re-identify the user when we have new information about them.
   */
  useEffect(() => {
    if (!ANALYTICS_ENABLED) return;
    if (window.pendo.isReady?.()) {
      if (!impersonating) {
        window.pendo.identify(pendoIdentity);
      }
    }
  }, [pendoIdentity, impersonating]);

  const pendoHasLoaded = !!window.pendo?.isReady;
  const pendoIsReady = window.pendo.isReady?.() || false;

  // Runs on every render but should only do something once when pendo isn't ready.
  useEffect(() => {
    if (!ANALYTICS_ENABLED) return;
    if (
      pendoHasLoaded && // Pendo has loaded
      !pendoIsReady // Pendo hasn't been initialized
    ) {
      if (!impersonating) {
        window.pendo.initialize(pendoIdentity); // pendo becomes ready after this
      }
    }
  }, [pendoHasLoaded, pendoIsReady, pendoIdentity, impersonating]);

  useEffect(() => {
    if (!ANALYTICS_ENABLED) return;
    if (impersonating) {
      window?.pendo?.teardown?.();
    }
  }, [impersonating]);
}

export function sendPendoEvent(event: string, metadata?: Record<string, any>) {
  if (!ANALYTICS_ENABLED) return;

  window.pendo.track(event, metadata);
}

export function useGoogleAnalytics() {
  const {
    email,
    profile: { uuid: userId },
  } = useUserState();
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!ANALYTICS_ENABLED) return;
    if (userId) {
      window.gtag('event', 'login', { user_id: userId, email });
      window.gtag('set', { user_id: userId, email });
    }
    dispatch(setUserIdentifiers(userId ? { userId, email } : null));
  });

  const { listen: historyListen } = history;
  const { refetch } = userApi.useGetUserStateQuery();
  useEffect(() => {
    trackPageView();
    historyListen(trackPageView);
    historyListen(refetch);
  }, [historyListen, refetch]);
}

/**
 * Send a user interaction event to Google Analytics.
 *
 * @param category The GA event category.
 * @param action The GA event action
 * @param label The GA event label.
 * @param value The GA event value, or any additional custom event properties.
 * @param params Additional event parameters
 */
export function sendAnalyticsEvent(
  category: string,
  action: string,
  label?: string,
  value?: number | object,
  params?: Record<string, any>
) {
  if (!ANALYTICS_ENABLED) return;

  try {
    const { userId, email } = store.getState().auth.userIdentifiers ?? {
      userId: undefined,
      email: undefined,
    };
    window.gtag('event', action, {
      event_category: category,
      ...(label ? { event_label: label } : {}),
      ...(value ? (typeof value === 'object' ? value : { value }) : {}),
      ...(userId ? { 'User ID': userId } : {}),
      ...(email ? { 'User Email': email } : {}),
      ...(params ? params : {}),
    });
  } catch (e) {
    console.error(e);
  }
}

export type SendPosthogEventFnType = (
  event: string,
  metadata?: Record<string, any>
) => void;

export function usePosthog() {
  const { email, profile, team, subscription, impersonating } = useUserState();

  const posthog = usePostHogProvider();

  /*
   * We use Posthog React Provider to initialize posthog, meaning that this should
   * occur after Posthog library is ready in the DOM.
   */
  useEffect(() => {
    // If Analytics flag is disabled, exit early
    if (!ANALYTICS_ENABLED) return;

    // TODO (sid): Identify sends event, consider limiting frequency of use here
    // Setup Posthog user identity
    posthog.identify(profile.uuid, {
      id: profile.uuid,
      name: profile.name,
      email,
      // TODO (sid): Consider impersonation - for now use user attribute to filter downstream
      impersonating, // for now, we'll track impersonation with a flag
    });

    // Setup Posthog group identity
    posthog.group('team', team.uuid, {
      id: team.uuid,
      name: team.name,
      subscription,
    });
  }, [posthog, profile, email, team, subscription, impersonating]);

  const sendPosthogEvent: SendPosthogEventFnType = (event, metadata) => {
    // If Analytics flag is disabled, exit early
    if (!ANALYTICS_ENABLED) return;

    // NOTE: events should be associated with the identity & group
    //       set at the posthog config level (in usePosthot() above)
    posthog.capture(event, metadata);
  };

  return { sendPosthogEvent };
}

export function trackPageView() {
  if (!ANALYTICS_ENABLED) return;

  try {
    const params = new URLSearchParams(window.location.search);
    [...params].filter(([key, _val]) => key.startsWith('utm_'));
    const { userId, email } = store.getState().auth.userIdentifiers ?? {
      userId: undefined,
      email: undefined,
    };
    window.gtag('event', 'page_view', {
      page_location: `${window.location.origin}${window.location.pathname}`,
      page_path: window.location.pathname,
      page_title: document.title,
      ...(userId ? { 'User ID': userId } : {}),
      ...(email ? { 'User Email': email } : {}),
    });
  } catch (e) {
    console.error(e);
  }
}
