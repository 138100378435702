import { useEffect, useMemo, useState } from 'react';
import { Avatar, Button, Container } from '@mui/material';
import { withAppAuthenticationRequired } from 'components/common/Auth/withAppAuthenticationRequired';
import { SectionLayout } from 'components/common/Widgets/SectionLayout';
import { useHistory, useParams } from 'react-router';
import { prospectsApi } from 'redux/reducers/api/prospects';
import {
  CustomGridColDef,
  DataGridProvider,
} from 'components/common/DataGrid/DataGridProvider';
import {
  ProspectListProfile,
  ProspectListOrganization,
} from 'modules/prospects/types';
import { Link } from 'react-router-dom';
import { formatNumber, getListPaths } from 'utils/util';
import { MuiIconManifest } from 'utils/iconManifest';
import { useGridMetaData } from 'components/common/Context/PageDataContext';

// #region column definitions

const commonColumns: CustomGridColDef<
  ProspectListProfile | ProspectListOrganization
>[] = [
  {
    field: 'image',
    headerName: '',
    renderCell: ({ value, row }) => (
      <Avatar alt={row.name} src={value} sx={{ width: 24, height: 24 }} />
    ),
    resizable: false,
    disableColumnMenu: true,
    sortable: false,
    width: 10,
    // custom column definitions not in GridColDef
    filterable: false,
    hideable: false,
  },
  {
    field: 'name',
    headerName: 'Name',
    renderCell: ({ value, row }) => <Link to={row.link}>{value}</Link>,
    flex: 3,
    // custom column definitions not in GridColDef
    filterable: false,
  },
  {
    field: 'events',
    headerName: '# of events',
    flex: 1,
    type: 'number',
    resizable: false,
    // custom column definitions not in GridColDef
    defaultFilter: true,
    valueGetterId: 'sortNumberIntoBuckets',
  },
];

const profileColumns: CustomGridColDef<
  ProspectListProfile | ProspectListOrganization
>[] = [
  ...commonColumns,
  {
    field: 'speaking',
    headerName: 'Speaking',
    flex: 1,
    resizable: false,
    type: 'number',
    // custom column definitions not in GridColDef
    defaultFilter: true,
    filterable: false,
  },
  {
    field: 'title',
    headerName: 'Title',
    flex: 3,
    // custom column definitions not in GridColDef
    defaultFilter: true,
  },
  {
    field: 'organization',
    headerName: 'Organization',
    flex: 3,
    // custom column definitions not in GridColDef
    defaultFilter: true,
  },
];

const organizationColumns: CustomGridColDef<
  ProspectListProfile | ProspectListOrganization
>[] = [
  ...commonColumns,
  {
    field: 'members',
    headerName: 'Members',
    sortable: true,
    flex: 1,
    type: 'number',
    // custom column definitions not in GridColDef
    defaultFilter: true,
    valueGetterId: 'sortNumberIntoBuckets',
  },
  {
    field: 'location',
    headerName: 'HQ location',
    sortable: true,
    flex: 3,
    // custom column definitions not in GridColDef
    defaultFilter: true,
  },
  {
    field: 'industry',
    headerName: 'Industry',
    sortable: true,
    flex: 2,
    // custom column definitions not in GridColDef
    defaultFilter: true,
  },
];

/**
 * Core component for rendering the profiles and organizations of a list using DataGridProvider.
 *
 * Jobs of this component:
 *
 * - Fetch all profiles and organizations of a list.
 * - Render the profiles and organizations using DataGridProvider.
 */
export function _ViewListPage() {
  const history = useHistory();
  const { listUuid: uuid } = useParams<{ listUuid: string }>();

  const { data: listData } = prospectsApi.useGetListQuery(uuid);

  const { data: profileData, isLoading: profileDataLoading } =
    prospectsApi.useGetProspectListProfilesQuery(uuid);
  const { data: orgData, isLoading: orgDataLoading } =
    prospectsApi.useGetProspectListOrganizationsQuery(uuid);

  const [selectedTabValue, setSelectedTabValue] = useState('profiles');
  const isLoading =
    selectedTabValue === 'profiles' ? profileDataLoading : orgDataLoading;
  const rows =
    selectedTabValue === 'profiles'
      ? profileData?.success
        ? profileData.profiles
        : []
      : orgData?.success
      ? orgData.organizations
      : [];

  const gridRef = useGridMetaData('list');

  // when data loads and there is no profile data, change tab to organizations
  useEffect(() => {
    if (
      !orgDataLoading &&
      !profileDataLoading &&
      profileData?.success &&
      profileData?.profiles.length === 0
    ) {
      setSelectedTabValue('organizations');
    }
  }, [profileData, profileDataLoading, orgData, orgDataLoading]);

  const columns = useMemo(() => {
    if (selectedTabValue === 'profiles') {
      return profileColumns;
    } else {
      return organizationColumns;
    }
  }, [selectedTabValue]);

  // count of profiles and organizations
  const profileCount = useMemo(
    () =>
      profileData?.success ? formatNumber(profileData.profiles.length) : 0,
    [profileData]
  );
  const orgCount = useMemo(
    () => (orgData?.success ? formatNumber(orgData.organizations.length) : 0),
    [orgData]
  );

  return (
    <>
      <SectionLayout
        title={listData?.results?.name || ''}
        slotProps={{
          container: {
            maxWidth: 'lg',
          },
        }}
        selectedTabValue={selectedTabValue}
        onTabChange={(event, newValue) => setSelectedTabValue(newValue)}
        tabs={[
          {
            label: `Profiles (${profileCount})`,
            value: 'profiles',
          },
          {
            label: `Organizations (${orgCount})`,
            value: 'organizations',
          },
        ]}
        rightsideToolbar={
          <Button
            startIcon={<MuiIconManifest.ManageAccountsIcon />}
            variant="rounded"
            color="primary"
            onClick={() => {
              history.push(getListPaths(listData.results).editPath);
            }}
          >
            Edit list
          </Button>
        }
      />
      <Container maxWidth="lg">
        <DataGridProvider<ProspectListProfile | ProspectListOrganization>
          apiRef={gridRef.ref}
          rows={rows}
          columns={columns}
          rowsLoading={isLoading}
          singularRowName={
            selectedTabValue === 'profiles' ? 'profile' : 'organization'
          }
          slotProps={{
            datagrid: {
              getRowId: (row) => row.uuid,
              noRowsOverlayText: `There are no ${selectedTabValue} in this list.`,
            },
          }}
        />
      </Container>
    </>
  );
}

export const ViewListPage = withAppAuthenticationRequired(_ViewListPage);
