import {
  CardContent,
  CardHeader,
  Link as MuiLink,
  Typography,
  Stack,
  Button,
  Box,
} from '@mui/material';
import { sendPendoEvent } from 'analytics';
import { AlertWithButton } from 'components/common/AlertWithButton';
import { LoadingIcon } from 'components/common/Widgets/LoadingIcon';
import { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { feedApi, InterestedEvent } from 'redux/reducers/api/feed';
import { MuiIconManifest } from 'utils/iconManifest';
import { formatNumber } from 'utils/util';

const NoInterestedEventsContent = () => (
  <Box display="flex" alignItems="center" justifyContent="center">
    <Stack spacing={4}>
      <Stack spacing={2} textAlign="center">
        <Typography fontWeight="bold">
          You haven't added any events yet!
        </Typography>
        <Typography>Let's get started adding some!</Typography>
      </Stack>
      <Button
        variant="rounded"
        color="primary"
        startIcon={<MuiIconManifest.SearchIcon fontSize="small" />}
        onClick={() => {
          window.location.href = '/app/events/search';
        }}
      >
        Explore all events
      </Button>
    </Stack>
  </Box>
);

const NoNewUpdatesContent = () => (
  <AlertWithButton
    message="There are no new event updates at this time."
    buttonLabel="Explore events"
    buttonIcon={<MuiIconManifest.SearchIcon />}
    buttonOnClick={() => {
      window.location.href = '/app/events/search';
    }}
    slotProps={{
      alert: {
        severity: 'warning',
      },
    }}
  />
);

const EventUpdatesContent = ({ events }: { events: InterestedEvent[] }) => (
  <Stack spacing={2} sx={{ maxHeight: 500, overflowY: 'auto' }}>
    {events.map((interestedEvent) => (
      <EventUpdate
        key={interestedEvent.event_uuid}
        interestedEvent={interestedEvent}
      />
    ))}
  </Stack>
);

export const AlertCenter = () => {
  const { data: eventUpdates, isLoading: eventUpdatesLoading } =
    feedApi.useGetEventUpdatesQuery();

  const getContent = () => {
    if (eventUpdatesLoading) return <LoadingIcon />;
    if (!eventUpdates || !eventUpdates.has_interested_events)
      return <NoInterestedEventsContent />;
    if (eventUpdates.interested_events.length === 0)
      return <NoNewUpdatesContent />;
    return <EventUpdatesContent events={eventUpdates.interested_events} />;
  };

  return (
    <>
      <CardHeader
        title={
          <Typography variant="h5">
            {`Event updates ${
              eventUpdates?.interested_events.length &&
              eventUpdates.has_interested_events
                ? `(${eventUpdates.interested_events.length})`
                : ''
            }`}
          </Typography>
        }
        avatar={<MuiIconManifest.NotificationsIcon />}
        sx={{ pb: 0 }}
      />
      <CardContent>{getContent()}</CardContent>
    </>
  );
};

const EventUpdate = ({
  interestedEvent,
}: {
  interestedEvent: InterestedEvent;
}) => {
  const history = useHistory();

  // build the URL depending on whether the user clicks the button or the event link
  const buildFullURL = useCallback(
    (isButton: boolean): string => {
      const basePath = `/app/event/${interestedEvent.event_slug}/${interestedEvent.event_uuid}`;
      const utmParams = new URLSearchParams({
        utm_source: 'dashboard',
        utm_medium: 'event_updates',
        utm_campaign: isButton ? 'download_button' : 'event_link',
      });

      return `${basePath}?${utmParams.toString()}`;
    },
    [interestedEvent.event_slug, interestedEvent.event_uuid]
  );

  return (
    <AlertWithButton
      message={
        <Typography>
          {formatNumber(interestedEvent.new_attendee_count)} new attendees were
          added to{' '}
          <MuiLink
            component={Link}
            to={buildFullURL(false)}
            onClick={() => {
              sendPendoEvent('Alert Center > Download', {
                event_uuid: interestedEvent.event_uuid,
                button_type: 'text_link',
              });
            }}
          >
            {interestedEvent.event_name}
          </MuiLink>
          .
        </Typography>
      }
      slotProps={{
        alert: {
          severity: 'info',
          icon: false,
        },
      }}
      buttonLabel="Download"
      buttonIcon={<MuiIconManifest.CloudDownloadIcon />}
      buttonOnClick={() => {
        sendPendoEvent('Alert Center > Download', {
          event_uuid: interestedEvent.event_uuid,
          button_type: 'download_button',
        });
        history.push(buildFullURL(true));
      }}
    />
  );
};
