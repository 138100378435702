import { GridValidRowModel } from '@mui/x-data-grid-premium';
import {
  CustomGridColDef,
  FilteredRow,
} from 'components/common/DataGrid/DataGridProvider';
import {
  PropCounts,
  RowCounts,
} from 'components/common/DataGrid/useGetRowCounts';
import dayjs from 'dayjs';
import { TableRow } from 'modules/event/types';
import { checkInBetweenDateValues, dateFilterValues } from 'utils/date';
import { profile } from 'utils/performance';
import { capitalizeFirstChar } from 'utils/util';

// #region Types

export interface RowCounterWorkerProps<T extends GridValidRowModel> {
  rows: T[] | FilteredRow<T>[];
  columns: CustomGridColDef<T>[];
}

// a function to run on the worker thread to get the value of the row for that column
// have to do it this way because functions are not serializable and cannot be passed to workers
function createValueGetterFunctions<T extends GridValidRowModel>() {
  const fns = profile('createValueGetterFunctions', () => {
    return {
      capitalizeFirstChar: ({ value }: ValueGetterParams<T>) =>
        capitalizeFirstChar(String(value)),
      isValueEqualToKey: ({ value, key }: ValueGetterParams<T>) =>
        value === key,
      getValueFromObject: ({ value, key }: ValueGetterParams<T>) => {
        if (key && value && typeof value === 'object' && key in value) {
          const val = (value as Record<string, unknown>)[key];
          return val !== null && val !== undefined ? val : undefined;
        }
        return undefined;
      },
      getValueFromArrayOfObjects: ({ value, key }: ValueGetterParams<T>) => {
        if (key && value && Array.isArray(value)) {
          const val = (value as Record<string, unknown>[]).map(
            (obj) => obj[key]
          );

          // dedupe and remove null, undefined, and blank values
          const formattedArray = Array.from(new Set(val)).filter(
            (v) => v !== null && v !== undefined && v !== ''
          );
          return formattedArray.length ? formattedArray : undefined; // return undefined if the array is empty
        }
        return undefined;
      },
      getDateAddedBuckets: ({ value }: ValueGetterParams<TableRow>) => {
        const parsedDayjs = dayjs(value as string);
        if (parsedDayjs.isValid()) {
          // loop through dateFilterValues and return the first key that the value is in between
          for (const [key, range] of dateFilterValues) {
            if (checkInBetweenDateValues(parsedDayjs, range)) {
              return key;
            }
          }
        }
      },
      // take the number and sort it into buckets, use the key to determine which bucket type
      sortNumberIntoBuckets: ({ value }: ValueGetterParams<TableRow>) => {
        const number = Number(value);
        if (number) {
          for (const range of bucketRanges) {
            if (number >= range.min && number <= range.max) {
              return range.label;
            }
          }
        }
        return undefined;
      },
      // add more valueGetter functions as needed
    };
  });

  return fns;
}

type ValueGetterParams<T extends GridValidRowModel> = {
  row: T | FilteredRow<T>;
  value: unknown;
  key?: string | number;
};

export type ValueGetterFunctions<T extends GridValidRowModel> = ReturnType<
  typeof createValueGetterFunctions<T>
>;

const bucketRanges = [
  { min: 0, max: 5, label: '0-5' },
  { min: 6, max: 10, label: '6-10' },
  { min: 11, max: 25, label: '11-25' },
  { min: 26, max: 50, label: '26-50' },
  { min: 51, max: 100, label: '51-100' },
  { min: 101, max: 250, label: '101-250' },
  { min: 251, max: 500, label: '251-500' },
  { min: 501, max: 1000, label: '501-1000' },
  { min: 1001, max: 5000, label: '1001-5000' },
  { min: 5001, max: 10000, label: '5001-10000' },
  { min: 10001, max: 50000, label: '10001-50000' },
  { min: 50001, max: 100000, label: '50001-100000' },
];

// #endregion

/**
 * Web worker to count unique values for each column in the provided rows.
 * Handles custom value extraction using `valueGetterFunctions` since functions can't be passed to workers.
 * Posts back the counts as a Map associating each field with unique values and their counts.
 */
export function getRowCounts<T extends GridValidRowModel>({
  rows,
  columns,
}: RowCounterWorkerProps<T>): RowCounts<T> {
  const allCounts = new Map<keyof T, PropCounts>();
  profile('getRowCounts', () => {
    const valueGetterFunctions = createValueGetterFunctions<T>();

    for (const column of columns) {
      const { field, valueGetterId, valueGetterKey } = column;
      const fieldToUse = field.includes('.') ? field.split('.')[0] : field;

      // type guard to check if valueGetterId is a key in valueGetterFunctions
      const getterFunction =
        valueGetterId && valueGetterId in valueGetterFunctions
          ? valueGetterFunctions[valueGetterId as keyof ValueGetterFunctions<T>]
          : null;

      // create a map for the field if it doesn't exist yet
      if (!allCounts.has(field)) {
        allCounts.set(field, new Map());
      }
      const countsForField = allCounts.get(field)!;

      // if using sortNumberIntoBuckets, add all values to the counts map even if it's empty
      if (valueGetterId === 'sortNumberIntoBuckets') {
        for (const range of bucketRanges) {
          if (!countsForField.has(range.label)) {
            countsForField.set(range.label, {
              count: 0,
              rowIds: [],
            });
          }
        }
      }

      /**
       * Generator function to iterate over the values for the field in each row.
       * If the value is an array, it will yield each value separately.
       * @returns {Generator<{ value: unknown, rowId: string }, void, unknown>}
       * @yields { value: unknown, rowId: string }
       */
      function* iterateValues() {
        for (const row of rows) {
          const rowData = 'model' in row ? row.model : row;

          const defaultValueForField = rowData[fieldToUse];
          const value =
            typeof getterFunction === 'function'
              ? getterFunction({
                  row: rowData,
                  value: defaultValueForField,
                  key: valueGetterKey,
                })
              : defaultValueForField;

          // if the value is an array, yield each value separately
          if (!Array.isArray(value)) {
            yield { value, rowId: row.id };
          } else {
            for (const val of value) {
              yield { value: val, rowId: row.id };
            }
          }
        }
      }

      // create a map of rowIds for each unique value
      const rowIdsByValue: Map<unknown, string[]> = new Map();
      for (const { value, rowId } of iterateValues()) {
        if (!rowIdsByValue.has(value)) {
          rowIdsByValue.set(value, []);
        }
        const rowIds = rowIdsByValue.get(value)!;
        rowIds.push(rowId);
      }

      // set the counts for the field
      for (const [value, rowIds] of rowIdsByValue) {
        countsForField.set(value, {
          count: rowIds.length,
          rowIds,
        });
      }
    }
  });

  return allCounts;
}
