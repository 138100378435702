import React from 'react';
import { Alert, Button, Stack, Typography } from '@mui/material';

interface AlertWithButtonProps {
  message: React.ReactNode;
  buttonLabel: string;
  buttonIcon?: React.ReactNode;
  buttonOnClick: () => void;
  slotProps?: {
    alert?: React.ComponentProps<typeof Alert>;
    button?: React.ComponentProps<typeof Button>;
  };
}

export const AlertWithButton = ({
  message,
  buttonLabel,
  buttonIcon,
  buttonOnClick,
  slotProps = {},
}: AlertWithButtonProps) => {
  const { alert: alertProps = {}, button: buttonProps = {} } = slotProps;

  return (
    <Alert
      sx={{
        alignItems: 'center',
        '& .MuiAlert-message': {
          width: '100%',
        },
      }}
      {...alertProps}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: '100%' }}
        spacing={2}
      >
        {typeof message === 'string' ? (
          <Typography variant="body1">{message}</Typography>
        ) : (
          message
        )}
        <Stack justifyContent="center">
          <Button
            onClick={buttonOnClick}
            size="small"
            variant="rounded"
            color="primary"
            startIcon={buttonIcon}
            {...buttonProps}
          >
            {buttonLabel}
          </Button>
        </Stack>
      </Stack>
    </Alert>
  );
};
