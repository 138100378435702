import { Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

/**
 * Used when a question is not found. Which shouldn't happen but most likely happened due to a change in the wizard config.
 */
export const NotFoundQuestion = () => {
  return (
    <Stack spacing={8}>
      <Stack spacing={2}>
        <Typography fontWeight={500} variant="h6">
          Question not found!
        </Typography>
        <Typography>
          <Link to="/app/myprospects/lists">Click here</Link> to go back to your
          lists.
        </Typography>
      </Stack>
    </Stack>
  );
};
