import { Stack, Typography } from '@mui/material';
import { TextFieldElement, useForm } from 'react-hook-form-mui';
import {
  WizardConfig,
  WizardTextInputQuestion,
} from 'components/Wizard/WizardConfig';
import { WizardPagination } from 'components/Wizard/WizardPagination';
import { useHistory } from 'react-router';
import { Wizard } from 'components/Wizard/Wizard';
import { useRef } from 'react';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { isSafeForSQL } from 'utils/util';

export interface TextInputQuestionProps<TWizard = Wizard<WizardConfig>> {
  question: WizardTextInputQuestion;
  initialValue: string;
  wizard: TWizard;
  onSubmitForm: (value: string) => void;
}

export function TextInputQuestion({
  question,
  initialValue,
  wizard,
  onSubmitForm,
}: TextInputQuestionProps) {
  const history = useHistory();
  const formRef = useRef<HTMLFormElement>(null);

  const defaultValues: Record<string, any> = {};
  defaultValues[question.name] = initialValue;

  const schema = z.object({
    [question.name]: z.string().min(1, {
      message: 'Please provide a value.',
    }),
  });

  type Schema = z.infer<typeof schema>;

  // Initialize form with default values from localStorage.
  const form = useForm<Schema>({
    defaultValues,
    resolver: zodResolver(schema),
  });

  function submitForm() {
    const value = form.getValues()[question.name];
    if (!isSafeForSQL(value)) {
      form.setError(question.name, {
        type: 'manual',
        message: 'Invalid input. Please try again with something else.',
      });
      return;
    } else {
      onSubmitForm(form.getValues()[question.name]);
    }
  }

  function onNextClick() {
    formRef.current?.requestSubmit();
  }

  function onPreviousClick() {
    history.goBack();
  }

  // the label and description can be a function that takes the wizard as an argument
  const questionLabel =
    typeof question.label === 'function'
      ? question.label(wizard)
      : question.label;
  const description =
    typeof question.description === 'function'
      ? question.description(wizard)
      : question.description;

  return (
    <Stack spacing={8}>
      <Stack spacing={2}>
        <Typography fontWeight={500} variant="h6">
          {questionLabel}
        </Typography>
        {question.description ? (
          <Typography variant="body2">{description}</Typography>
        ) : null}
        <form onSubmit={form.handleSubmit(submitForm)} ref={formRef}>
          <TextFieldElement
            fullWidth
            control={form.control}
            name={question.name}
            placeholder={question.placeholder}
            onChange={() => {
              form.clearErrors(question.name); // clear errors when user types
            }}
            onKeyDown={(event) => {
              if (!isSafeForSQL(event.key)) {
                event.preventDefault();
                form.setError(question.name, {
                  type: 'manual',
                  message: 'Invalid key pressed.',
                });
              }
            }}
          />
        </form>
      </Stack>
      <WizardPagination
        disabled={!form.formState.isValid}
        wizard={wizard}
        question={question}
        onNextClick={onNextClick}
        onPreviousClick={onPreviousClick}
      />
    </Stack>
  );
}
