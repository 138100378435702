import React, { SyntheticEvent } from 'react';
import { Button, Grid } from '@mui/material';
import { AnyWizardQuestion, WizardConfig } from './WizardConfig';
import { Wizard } from './Wizard';
import {
  sendAnalyticsEvent,
  sendPendoEvent,
  SendPosthogEventFnType,
  usePosthog,
} from 'analytics';
import { MuiIconManifest } from 'utils/iconManifest';

export interface WizardPaginationProps<TWizard = Wizard<WizardConfig>> {
  wizard: TWizard;
  question: AnyWizardQuestion;
  onPreviousClick: (e: SyntheticEvent) => void;
  onNextClick?: (e: SyntheticEvent) => void;
  finalQuestion?: boolean;
  canGoBack?: boolean;
  canGoForward?: boolean;
  disabled?: boolean;
}

export function trackWizardPaginationButtonClick(
  eventLabel: string,
  wizard: Wizard,
  question: AnyWizardQuestion,
  sendPosthogEvent: SendPosthogEventFnType
) {
  const params = {
    questionId: question.id,
    wizardId: wizard.config.id,
    wizardVersion: wizard.config.version,
    questionLabel:
      typeof question.label === 'function'
        ? question.label(wizard)
        : question.label,
    questionType: question.type,
    questionSubType: question.subType || '',
  };
  sendPendoEvent(eventLabel, params);
  sendAnalyticsEvent('List Wizard', eventLabel, undefined, undefined, params);
  sendPosthogEvent(eventLabel, params);
}

export function trackPrevButtonClick(
  wizard: Wizard,
  question: AnyWizardQuestion,
  sendPosthogEvent: SendPosthogEventFnType
) {
  trackWizardPaginationButtonClick(
    'List Wizard > Previous Button Clicked',
    wizard,
    question,
    sendPosthogEvent
  );
}

export function trackNextButtonClick(
  wizard: Wizard,
  question: AnyWizardQuestion,
  sendPosthogEvent: SendPosthogEventFnType
) {
  trackWizardPaginationButtonClick(
    'List Wizard > Next Button Clicked',
    wizard,
    question,
    sendPosthogEvent
  );
}

/**
 * Prev/next buttons for use in wizard question components.
 *
 * This is included manually in each question component since:
 * 1. Not all question types have pagination.
 * 2. Question types operate a bit differently.
 *
 * In the future we may make this more generic and include it once within <AnyWizardQuestionPage />.
 */
export function WizardPagination({
  wizard,
  question,
  onNextClick,
  onPreviousClick,
  canGoBack = true,
  canGoForward = true,
  disabled,
}: WizardPaginationProps) {
  const { sendPosthogEvent } = usePosthog();

  const previousButtonText = wizard.getPreviousButtonText(question.id);
  const nextButtonText = wizard.getNextButtonText(question.id);

  const combinedOnPreviousClick = (e: SyntheticEvent) => {
    trackPrevButtonClick(wizard, question, sendPosthogEvent);
    onPreviousClick(e);
  };

  const combinedOnNextClick = (e: SyntheticEvent) => {
    trackNextButtonClick(wizard, question, sendPosthogEvent);
    onNextClick?.(e);
  };

  return (
    <Grid container mt={20} alignItems="center" justifyContent="space-between">
      <Grid item>
        {canGoBack ? (
          <Button
            variant="roundedOutlined"
            onClick={combinedOnPreviousClick}
            startIcon={<MuiIconManifest.ArrowBackIosNewIcon />}
          >
            {previousButtonText}
          </Button>
        ) : null}
      </Grid>
      <Grid ml={2} item>
        {canGoForward ? (
          <Button
            variant="rounded"
            disabled={disabled}
            onClick={combinedOnNextClick}
            endIcon={<MuiIconManifest.ArrowForwardIosIcon />}
          >
            {nextButtonText}
          </Button>
        ) : null}
      </Grid>
    </Grid>
  );
}
