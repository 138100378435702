import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { getFormattedDateInterval, dayJsIsoDateFormat } from 'utils/date';
import {
  getFormattedCost,
  viewEventLabelTextByAttendeeCount,
} from 'utils/util';
import { VerifiedBadge } from './VerifiedBadge';
import { CardHeader, Stack, Tooltip } from '@mui/material';
import { AvatarList, Avatars } from 'components/Explore/AvatarList';
import { EventForCard } from 'modules/search/types';
import { MuiIconManifest } from 'utils/iconManifest';

// Extending dayjs because we want to convert timestamp to utc date
dayjs.extend(utc);

interface EventCardProps {
  event: EventForCard;
  defaultHeight?: number;
  onItemClick?: (event: EventForCard) => void;
  avatars?: Avatars;
  hideCount?: boolean;
  algolia?: boolean;
}

export default function EventCard({
  event,
  defaultHeight = 140,
  onItemClick,
  avatars,
  hideCount = false,
  algolia = false, // This is a flag to indicate that the data is coming from algolia
}: EventCardProps) {
  // converting timestamp comes from algolia to proper date format.
  const startDateFixed = algolia ? event.date_start * 1000 : event.date_start;
  const endDateFixed = algolia ? event.date_end * 1000 : event.date_end;
  const endDate = dayJsIsoDateFormat(new Date(endDateFixed));
  const startDate = dayJsIsoDateFormat(new Date(startDateFixed));

  const eventUrl = !event.url
    ? `/app/event/${event.slug}/${event.uuid}`
    : event.url;

  let url = new URL(
    window.location.protocol + '//' + window.location.hostname + eventUrl
  );
  const event_url = url.pathname;
  let link_object = {
    pathname: (event_url || '').replace('https://vendelux.com', ''),
    state: event.__queryID ? { queryID: event.__queryID } : {},
  };

  return (
    <Card sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardActionArea>
        <Link to={link_object} onClick={(e) => onItemClick?.(event)}>
          <CardMedia
            component="img"
            alt={`${event.name} banner`}
            height={defaultHeight}
            image={
              event.bimage ||
              event.image ||
              '/media/pic_folder/placeholders/Oval.png'
            }
            title={`${event.name} banner`}
          />
        </Link>
      </CardActionArea>
      <CardHeader
        title={
          <Typography variant="h6" noWrap>
            {event.name}
            {event.verified ? <VerifiedBadge fontSize={'medium'} /> : null}
          </Typography>
        }
        subheader={getFormattedDateInterval(startDate, endDate)}
        sx={{
          '& .MuiCardHeader-content': {
            width: '100%',
          },
        }}
      />
      <CardContent sx={{ pt: 0, flex: 1 }}>
        <Stack spacing={2}>
          <Stack alignItems="center" direction="row" spacing={4}>
            <Tooltip arrow title="Event city">
              <MuiIconManifest.MapIcon color="primary" />
            </Tooltip>
            <Typography variant="body2" noWrap>
              {event.city || 'Digital'}
            </Typography>
          </Stack>
          <Stack alignItems="center" direction="row" spacing={4}>
            <Tooltip arrow title="Event venue">
              <MuiIconManifest.LocationCityIcon color="primary" />
            </Tooltip>
            <Typography variant="body2" noWrap>
              {event.venue || 'Online'}
            </Typography>
          </Stack>
          <Stack alignItems="center" direction="row" spacing={4}>
            <Tooltip arrow title="Event cost">
              <MuiIconManifest.PaidIcon color="primary" />
            </Tooltip>
            <Typography variant="body2" noWrap>
              {getFormattedCost(event.price_lower, event.price_upper)}
            </Typography>
          </Stack>
          {avatars && <AvatarList avatars={avatars} />}
        </Stack>
      </CardContent>

      <CardActions disableSpacing={false}>
        <Button
          size="small"
          color="primary"
          variant="roundedOutlined"
          fullWidth
          component={Link}
          to={link_object}
          onClick={() => onItemClick?.(event)}
          startIcon={<MuiIconManifest.SearchIcon />}
        >
          {hideCount
            ? 'View event'
            : viewEventLabelTextByAttendeeCount(event.attendee_count)}
        </Button>
      </CardActions>
    </Card>
  );
}
