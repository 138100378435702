import { WizardQuestionPage } from 'components/Wizard/WizardQuestionPage';
import { withAppAuthenticationRequired } from 'components/common/Auth/withAppAuthenticationRequired';
import { listWizardConfig } from './listWizardConfig';
import { useParams } from 'react-router';
import { useListWizard } from 'components/Wizard/useListWizard';
import { useEffect, useState } from 'react';
import { LoadingIcon } from 'components/common/Widgets/LoadingIcon';
import { sendAnalyticsEvent, sendPendoEvent, usePosthog } from 'analytics';

function _NewListWizardPage() {
  const { sendPosthogEvent } = usePosthog();

  const [hasBootstrapped, setHasBootstrapped] = useState<boolean>(false);
  const [hasSentAnalytics, setHasSentAnalytics] = useState<boolean>(false);
  const { questionId } = useParams<{ questionId: string }>(); // Type assertion

  const { wizard, callbacks } = useListWizard({
    config: listWizardConfig,
    mode: 'create',
  });

  // Did we just enter the wizard?
  const isWizardStartPage = wizard && !questionId;
  const pendoIsReady = window.pendo?.isReady?.();

  // If we just clicked 'new list', clear the storage and start fresh.
  useEffect(() => {
    // Ensure that localStorage is clear of any lists we were creating/editing previously.
    if (isWizardStartPage && !hasBootstrapped) {
      wizard.clearStorage();
    }
    setHasBootstrapped(true);
  }, [wizard, isWizardStartPage, hasBootstrapped]);

  useEffect(() => {
    if (isWizardStartPage && pendoIsReady && !hasSentAnalytics) {
      sendPendoEvent('List Wizard > Create List > Start', {});
      sendAnalyticsEvent('List Wizard', 'List Wizard > Start');
      sendPosthogEvent('List Wizard > Create List > Start');
      setHasSentAnalytics(true);
    }
  }, [
    isWizardStartPage,
    hasSentAnalytics,
    setHasSentAnalytics,
    pendoIsReady,
    sendPosthogEvent,
  ]);

  // Default to first question is none specified in the URL.
  const question = wizard.questions.byId(
    questionId ? questionId : listWizardConfig.questions[0].id
  );

  if (!hasBootstrapped) {
    return <LoadingIcon />;
  }

  return (
    <WizardQuestionPage
      wizard={wizard}
      currentQuestionId={question?.id}
      callbacks={callbacks}
    />
  );
}

export const NewListWizardPage =
  withAppAuthenticationRequired(_NewListWizardPage);

export default NewListWizardPage;
