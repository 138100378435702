import { BetaBadge } from 'components/common/Widgets/BetaBadge';
import { Button, Stack, Typography } from '@mui/material';
import { ComponentProps } from 'react';
import { MuiIconManifest, MuiIconName } from 'utils/iconManifest';

export type FilterBoxProps = {
  title: string;
  description?: string;
  onClick?: () => void;
  activated: boolean;
  isBeta?: boolean;
  disabled?: boolean;
  icon?: MuiIconName;
  slotProps?: {
    button: ComponentProps<typeof Button>;
  };
};

export const FilterBox = ({
  title,
  description,
  onClick,
  activated,
  isBeta,
  disabled,
  slotProps,
  icon = 'UploadFileOutlinedIcon',
}: FilterBoxProps) => {
  const IconComponent = MuiIconManifest[icon];

  if (!title) return null;
  return (
    <Button
      disabled={disabled}
      variant="outlined"
      size="large"
      sx={{
        justifyContent: 'flex-start',
      }}
      color={activated ? 'primary' : 'grey'}
      onClick={onClick}
      // DEV-3153 - Add custom class for Pendo css selection
      className={`pendo-filter-button-${title
        ?.toLowerCase()
        .replace(/[^a-z0-9]/gi, '-')}`}
      {...slotProps?.button} // overrideable props
    >
      <Stack direction="row" spacing={4} alignItems="center">
        <IconComponent fontSize="medium" />
        <Stack direction="column">
          <Stack direction="row" spacing={1}>
            <Typography fontWeight={500} textAlign="left" alignItems="center">
              {title}
              {isBeta && <BetaBadge />}
            </Typography>
          </Stack>
          {description ? (
            <Typography variant="body2" textAlign="left">
              {description}
            </Typography>
          ) : null}
        </Stack>
      </Stack>
    </Button>
  );
};
