/**
 * Add dashes to a UUID string if they've been stripped and convert to lowercase.
 */
export function normalizeUUID(uuid: string): string {
  const stripped = uuid.replace(/-/g, '').toLowerCase();
  if (stripped.length !== 32 || stripped.match(/[^0-9a-f]/)) {
    throw new Error(`Invalid UUID: ${uuid}`);
  }
  const parts = [
    stripped.slice(0, 8),
    stripped.slice(8, 12),
    stripped.slice(12, 16),
    stripped.slice(16, 20),
    stripped.slice(20),
  ];
  return parts.join('-');
}

/**
 * Attempt to normalize a UUID string, returning the original string if it fails.
 *
 * Use this if you filter out invalid UUIDs in a separate step.
 */
export function safeNormalizeUUID(uuid: string): string {
  try {
    return normalizeUUID(uuid);
  } catch (e) {
    return uuid;
  }
}
