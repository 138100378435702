import { api } from '../redux/reducers/api';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { endpoints } from 'variables/endpoint-urls';
import { useCallback } from 'react';
import { usePostHog } from 'posthog-js/react';

/*
 * Clear RTK Query cache then log out of Auth0 and the backend.
 *
 * Ensures the Auth0 logout only happens after the backend logout since it
 * redirects away from the app.
 *
 * @param logout - Auth0 logout function from `useAuth0()` hook
 * @param returnTo - URL to redirect to after logout
 * @param local - Set to `false` to skip the backend logout
 */
export function appLogout(
  logout: ReturnType<typeof useAuth0>['logout'],
  { returnTo, local = true }: { returnTo?: string; local?: boolean } = {},
  posthog?: ReturnType<typeof usePostHog>
) {
  posthog?.reset(); // reset posthog user data

  // maybe not necessary bcs of the redirect but won't hurt
  api.util.resetApiState();
  const localLogout = local
    ? axios.post(endpoints.auth.post.logout)
    : Promise.resolve();
  localLogout
    .catch(console.error)
    .then(() =>
      logout({
        logoutParams: {
          returnTo: returnTo ?? window.location.origin + '/app/events/search',
        },
      })
    )
    .catch(console.error);
}

export function useAppLogout() {
  const posthog = usePostHog();
  const { logout } = useAuth0();
  return useCallback(
    (options: Parameters<typeof appLogout>[1] = {}) => {
      appLogout(logout, options, posthog);
    },
    [logout, posthog]
  );
}
