import { TextField, TextFieldProps } from '@mui/material';
import React, { ChangeEvent } from 'react';

export type CurrencyInputProps = {
  value: string;
  onChangeValue: (value: number) => void;
  loading?: boolean;
} & TextFieldProps;

export function CurrencyInput({
  value,
  onChangeValue,
  loading = false,
  ...props
}: CurrencyInputProps) {
  const formatCurrency = (value: string) => {
    if (!value) return '';

    const onlyDigits = Number(value.replace(/\D/g, ''));
    return onlyDigits.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const inputValue = e.target.value;
    const formattedValue = formatCurrency(inputValue);
    const numericValue = formattedValue.replace(/\D/g, '');

    onChangeValue(Number(numericValue));
  };

  return (
    <TextField
      size="small"
      onChange={(e) => {
        handleInputChange(e);
      }}
      sx={{ bgcolor: 'white !important' }}
      value={loading ? 'Loading...' : formatCurrency(value)}
      disabled={loading}
      inputProps={{
        inputMode: 'numeric',
        pattern: '[0-9]*',
        maxLength: 10,
      }}
      helperText="The maximum deal size is $1 billion."
      {...props}
    />
  );
}
